<template>
  <div>
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="font-weight-bold text-uppercase">Notification Note Code</h1>
          </b-col>
        </b-row>
        <b-row
          class="no-gutters bg-white-border mt-4"
          v-if="items"
          v-bind:class="$isLoading ? 'd-none' : 'd-block'"
        >
          <b-col class="px-4 px-sm-5 py-4">
            <div class="mb-3" v-for="(item, index) in items" :key="index">
              <div class="position-relative">
                <b-button
                  v-b-toggle :href="`#collapse-${index}`" @click.prevent
                  variant="primary"
                  class="seo-btn"
                  >{{item.header}}</b-button
                >
                <font-awesome-icon icon="sort-down" class="arrow-logo r-15" />
              </div>
              <b-collapse visible :id="`collapse-${index}`" class="mt-2">
                <div v-for="(item2, index2) in item.detail" :key="index2">
                  <b-card v-if="item2.sortOrder === 1" title="Header" class="mb-2">
                    <b-card-text>
                      {{item2.message}}
                    </b-card-text>
                    <button
                      type="button"
                      class="btn btn-edit md-2 text-uppercase"
                      @click="setEditText(item, item2)"
                      :disabled="isDisable"
                    >
                      Edit
                    </button>
                  </b-card>
                  <b-card v-if="item2.sortOrder === 2" title="Notification" class="mb-2">
                    <b-card-text>
                      {{item2.message}}
                    </b-card-text>
                    <button
                      type="button"
                      class="btn btn-edit md-2 text-uppercase"
                      @click="setEditText(item, item2)"
                      :disabled="isDisable"
                    >
                      Edit
                    </button>
                  </b-card>
                  <b-card v-if="item2.sortOrder === 3" title="Footer" class="mb-2">
                    <b-card-text>
                      {{item2.message}}
                    </b-card-text>
                    <button
                      type="button"
                      class="btn btn-edit md-2 text-uppercase"
                      @click="setEditText(item, item2)"
                      :disabled="isDisable"
                    >
                      Edit
                    </button>
                  </b-card>
                </div>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </form>

    <!-- Modal Result -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <b-modal 
      ref="editTextValue"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      size="lg"
    >
      <div class="modal-header  border-0 px-0 pt-0">
        <h3 class="font-weight-bold">{{modalHeaders}}</h3>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$refs['editTextValue'].hide()"
        >
          ×
        </button>
      </div>
      <div>
        <b-container>
          <div>
            <b-button v-for="(variable,i) in variableItem" :key="i" class="btn-color px-3 mb-3 mr-3" variant="outline" @click="AddVariable(variable)">{{variable.name}}</b-button>
          </div>
          <div>
            <b-form-textarea
              ref="textarea"
              v-model="modalEditValue"
              placeholder="Enter something..."
              rows="10"
            ></b-form-textarea>
          </div>
          <!-- <div v-if="$v.modalEditValue && $v.modalEditValue.$error">
            <span class="text-error" v-if="$v.modalEditValue.required == false">This field can’t be empty</span>
          </div> -->
          <b-row>
            <b-col md="6" class="text-left pb-3 mt-4">
              <b-button class="cancel-btn-color px-4" variant="outline" @click="Cancel">Cancel</b-button>
            </b-col>
            <b-col md="6" class="text-right pb-3 mt-4">
              <b-button class="btn-edit-color px-4" variant="outline" @click="Edit()">Save</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import { required } from "vuelidate/lib/validators";

export default {
  name: "notificationnotecode",
  components: {
    ModalAlert,
    ModalAlertError,
  },
  data() {
    return {
      img: "",
      isDisable: false,
      isLoading: false,
      isBusy: false,
      modalMessage: "",
      items: null,
      variableData: [],
      variableItem: [],
      modalEditValue: "",
      modalHeaders: "",
      form: {
        id: null,
        noteCodeTypeId: null,
        message: "",
        sortOrder: null
      }
    };
  },
  validations: {
    modalEditValue: { required }
  },
  created: async function () {
    await this.getData();
  },
  methods: {
    getData: async function () {
      this.$isLoading = true;
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/NoteCode/GetTemplateNoteCodeNotiList`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        for (let i  = 0; i  < resData.detail.length; i ++) {
          if (resData.detail[i].noteCodeTypeId === 5) {
            resData.detail[i].header = "Notification Installment"
          } else if (resData.detail[i].noteCodeTypeId === 6) {
            resData.detail[i].header = "Notification Overdue"
          } else if (resData.detail[i].noteCodeTypeId === 7) {
            resData.detail[i].header = "Notification Overdue1"
          } else if (resData.detail[i].noteCodeTypeId === 8) {
            resData.detail[i].header = "Notification Receipt"
          } else if (resData.detail[i].noteCodeTypeId === 9) {
            resData.detail[i].header = "Notification Receipt Cancel"
          } else if (resData.detail[i].noteCodeTypeId === 10) {
            resData.detail[i].header = "Notification Return Cash"
          } else if (resData.detail[i].noteCodeTypeId === 11) {
            resData.detail[i].header = "Notification Tax"
          } else if (resData.detail[i].noteCodeTypeId === 12) {
            resData.detail[i].header = "Notification Point"
          } else if (resData.detail[i].noteCodeTypeId === 13) {
            resData.detail[i].header = "Notification RI"
          }
        }
        this.items = resData.detail;
        this.$isLoading = false;
      }
      this.getNoteCodeVariable()
    },
    async getNoteCodeVariable () {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/NoteCode/GetTemplateNoteCodeNotiVariable`,
        null,
        this.$headers,
        null
      );
      console.log('resData', resData);
      this.variableData = resData.detail
    },
    setEditText(value, value2) {
      // console.log('value', value);
      // console.log('value2', value2);
      this.form.noteCodeTypeId = value.noteCodeTypeId
      this.form.id = value2.id
      this.form.sortOrder = value2.sortOrder
      this.modalEditValue = value2.message  
      if (value.noteCodeTypeId === 5) {
        this.modalHeaders = "Notification Installment"
      } else if (value.noteCodeTypeId === 6) {
        this.modalHeaders = "Notification Overdue"
      } else if (value.noteCodeTypeId === 7) {
        this.modalHeaders = "Notification Overdue1"
      } else if (value.noteCodeTypeId === 8) {
        this.modalHeaders = "Notification Receipt"
      } else if (value.noteCodeTypeId === 9) {
        this.modalHeaders = "Notification Receipt Cancel"
      } else if (value.noteCodeTypeId === 10) {
        this.modalHeaders = "Notification Return Cash"
      } else if (value.noteCodeTypeId === 11) {
        this.modalHeaders = "Notification Tax"
      } else if (value.noteCodeTypeId === 12) {
        this.modalHeaders = "Notification Point"
      } else if (value.noteCodeTypeId === 13) {
        this.modalHeaders = "Notification RI"
      }
      for (let i = 0; i < this.variableData.length; i++) {
        if (value.noteCodeTypeId === this.variableData[i].noteCodeTypeId) {
          this.variableItem = this.variableData[i].detail
        }
      }
      this.$refs['editTextValue'].show()
    },
    AddVariable(variable) {
      const insertText = variable.name
      if (!insertText.length) return
      const textarea = this.$refs.textarea
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }
      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)
      this.modalEditValue = before + `{{${insertText}}}` + after
      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length
      })
    },
    Cancel() {
      this.$refs['editTextValue'].hide()
    },
    async Edit() {
      this.form.message = this.modalEditValue
      // console.log("this.form", this.form);
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/NoteCode/EditTemplateNoteCodeNotiList`,
        null,
        this.$headers,
        this.form
      );
      if (resData.result === 1) {
        this.$refs['editTextValue'].hide()
        this.modalMessage = "แก้ไขข้อมูลเรียบร้อย"
        this.$refs.modalAlert.show()
        this.getData()
      }
      // console.log('edit ===', resData)
    }
  },
};
</script>
<style scoped>
.btn-edit {
  background-color: #80C141 !important;
  color: #FFFFFF !important;
  font-weight: bold;
}
.btn-color {
  color: #80C141;
  border-color: #80C141;
}
.btn-color:hover {
  color: #ffffff;
  background-color: #80C141;
  border-color: #80C141;
}
.cancel-btn-color{
  border-color: #000000;
}
.btn-edit-color{
  color: #ffffff;
  border-color: #80C141;
  background-color: #80C141 !important;
}
</style>